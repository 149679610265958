import Alpine from 'alpinejs'
import collapse from '@alpinejs/collapse'
import persist from '@alpinejs/persist'
import anchor from '@alpinejs/anchor'
import intersect from '@alpinejs/intersect'
import morph from '@alpinejs/morph'
import mask from '@alpinejs/mask'
import flatpickr from 'flatpickr'
import Sortable from 'sortablejs'

import htmx from 'htmx.org/dist/htmx.esm'


htmx.config.refreshOnHistoryMiss = true

window.Sortable = Sortable


if (!!window.appConfig) {
    if (window.appConfig.debug) {
        // htmx.logAll()
    }
}

Alpine.plugin(collapse)
Alpine.plugin(persist)
Alpine.plugin(anchor)
Alpine.plugin(intersect)
Alpine.plugin(morph)
Alpine.plugin(mask)


window.Alpine = Alpine;

window.htmx = htmx;

(() => {
    // require('htmx.org/dist/ext/head-support')
    require('htmx-ext-alpine-morph')
    require('htmx-ext-restored')
    window.marked = require('marked')
})()


// Alpine.start()

window.document.addEventListener('htmx:configRequest', (ev) => {
    ev.detail.headers['X-CSRFToken'] = window.appConfig.csrfToken
})
